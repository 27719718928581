import classNames from "classnames";
import { ForwardedRef, HTMLProps, forwardRef } from "react";
import { Text } from "../Text";
import { Skeleton } from "@mui/material";

type Padding = "none" | "small" | "default";

const PADDING_STYLES: Record<Padding, string> = {
  none: "",
  small: "p-100 rounded-sm",
  default: "p-200 rounded",
};

interface ActionListButtonProps extends HTMLProps<HTMLButtonElement> {
  loading?: boolean;
  onClick: (args: any) => void;
  active?: boolean;
  border?: boolean;
  disabled?: boolean;
  variant?: "default" | "critical";
  padding?: Padding;
}

export const ActionListButton = forwardRef(
  (
    {
      active = false,
      loading = false,
      onClick,
      children,
      border = false,
      disabled = false,
      className,
      onKeyDown,
      variant = "default",
      padding = "default",
    }: ActionListButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const WrapperElement = typeof children === "string" ? Text : "span";

    if (loading) {
      return (
        <Skeleton
          className={classNames(
            "min-h-[32px] w-full",
            PADDING_STYLES[padding],
            className,
          )}
          variant="rectangular"
          animation="pulse"
        />
      );
    }

    return (
      <button
        disabled={disabled || loading}
        className={classNames(
          "rounded text-left transition",
          variant === "default" && "enabled:hover:bg-card-hover",
          PADDING_STYLES[padding],
          border && variant === "default" && "border",
          border && variant === "default" && active && "border-dark",
          border && variant === "critical" && "border border-critical",
          border && variant === "critical" && active && "border-critical",
          variant === "critical" && "enabled:hover:bg-card-critical-hover",
          active && "bg-card-selected",
          className,
        )}
        onClick={onClick}
        type="button"
        ref={ref}
        onKeyDown={onKeyDown}
      >
        <WrapperElement
          variant={active ? "headingSm" : "bodyMd"}
          color={variant}
          className={loading ? "text-disabled" : ""}
        >
          {children}
        </WrapperElement>
      </button>
    );
  },
);
